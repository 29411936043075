.project.glo {
  background-color: #0D1216;

  a {
    color: #19ACAC;
  }

  .hero {
    @media (max-width: $mobile-max) {
      margin-bottom: 98px;
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $desktop-min) {
      background: url('../img/projects/glo_hero_background.svg');
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 50%;

      .project-content {
        width: 80%;
      }
    }
  }

  .project-content {
    margin-top: 99px;

    @media (min-width: $desktop-min) {
      margin-top: 166px;
    }
  }

  @media (min-width: $desktop-min) {
    .see-more {
      margin-left: 10%;
    }
  }

  .project-row {
    border-bottom: $project-border;

    &.top {
      @media (max-width: $mobile-max) {
        padding-bottom: 4px;
        padding-top: 14px;
      }

      @media (min-width: $desktop-min) {
        .col.first {
          padding-right: 10%;
        }

        .col.last {
          .video-container {
            padding-bottom: 20%;
            position: relative;
          }

          h5 {
            width: 60%;
          }
        }
      }
    }

    &.bottom {
      @media (max-width: $mobile-max) {
        padding-top: 0;
      }

      .detail {
        @media (max-width: $mobile-max) {
          margin-bottom: 80px;
        }
        @media (min-width: $desktop-min) {
          max-width: 420px;
        }
      }

      @media (min-width: $desktop-min) {
        .col.last {
          padding-left: 10%;
        }
      }

      p {
        margin-top: 29px;
      }

      .quote-container {
        margin-top: 89px;

        &.mobile-hide {
          border-top: $project-border;
          padding-top: 87px;
        }
      }
    }
  }
}
