.project.spkr {
  a {
    color: #EAD13E;
  }

  .hero {
    margin-bottom: 100px;
    position: relative;

    @media (min-width: $desktop-min) {
      &:before {
        background: url('../img/projects/spkr_hero_background.svg');
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 100%;
        bottom: -20%;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        width: 50%;
      }
    }

    video {
      width: 100%;
    }
  }

  .project-content {
    margin-top: 99px;

    @media (min-width: $desktop-min) {
      margin-top: 166px;
    }
  }

  @media (min-width: $desktop-min) {
    img.grid {
      margin-bottom: 85px;
    }
  }

  .project-row {
    border-bottom: $project-border;

    &.top {
      @media (max-width: $mobile-max) {
        padding-bottom: 4px;
        padding-top: 12px;

        .col.last {
          padding-top: 45px;
        }
      }

      @media (min-width: $desktop-min) {
        .col.first {
          padding-right: 10%;
        }

        h5 {
          width: 80%;
        }
      }
    }

    &.bottom {
      @media (max-width: $mobile-max) {
        padding-top: 44px;

        .col.last {
          border-top: $project-border;
          padding-top: 80px;
        }
      }

      @media (min-width: $desktop-min) {
        .col.last {
          padding-left: 10%;
        }
      }
    }
  }

  .details-top {
    border-bottom: $project-border;

    @media (max-width: $mobile-max) {
      margin-bottom: 3px;
      padding-bottom: 59px;
    }

    @media (min-width: $desktop-min) {
      display: flex;
      margin-bottom: 95px;
      padding-bottom: 80px;

      .col {
        width: 50%;

        &:first-child {
          padding-right: 5%;
        }
      }
    }
  }

  @media (min-width: $desktop-min) {
    .see-more {
      margin-left: 10%;
    }
  }
}
