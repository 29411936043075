section.projects {
  padding-top: 74px;

  @media (min-width: $desktop-min) {
    padding-top: 14%;
  }

  h3 {
    font-size: 57px;
    line-height: 68px;
    padding-bottom: 52px;

    @media (min-width: $desktop-min) {
      font-size: 120px;
      line-height: 144px;
      padding-left: 24%;
    }
  }

  h4 {
    font-size: 42px;
    line-height: 50px;
  }

  h5 {
    color: #FFFFFF;
    margin-bottom: 22px;
    margin-top: 65px;

    @media (max-width: $mobile-max) {
      font-size: 40px;
      line-height: 48px;
    }

    @media (min-width: $desktop-min) {
      margin-bottom: 22px;
      margin-top: 70px;

      span {
        display: block;
      }
    }

    &.subtitle {
      margin-top: 69px;

      @media (min-width: $desktop-min) {
        margin-top: 0;
        width: $project-right-col-width;
      }
    }
  }

  p, ul {
    font-weight: 300;
    font-size: 21px;
    line-height: 28px;

    @media (min-width: $desktop-min) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .mobile-video {
    margin: 50px 0;

    @media (min-width: $desktop-min) {
      display: none;
    }
  }

  .desktop-video {
    display: none;

    @media (min-width: $desktop-min) {
      display: block;
    }
  }

  @media (max-width: $mobile-max) {
    .mobile-border {
      border-bottom: $project-border;
      padding-bottom: 58px;
    }
  }

  .video-inline {
    display: block;
    margin: 40px auto;

    @media (min-width: $desktop-min) {
      margin: 0;
      max-height: none;
    }
  }

  .dots {
    border-top: $project-border;
    display: inline-flex;
    margin-top: 13px;
    padding-top: 20px;

    span {
      border-radius: 100%;
      display: block;
      height: 8px;
      margin-right: 10px;
      width: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tags {
    font-family: $font-mono;
    font-size: 12px;

    @media (min-width: $desktop-min) {
      margin-bottom: 60px;
    }
  }
}

.project {
  padding: 87px 0;

  @media (min-width: $desktop-min) {
    padding: 300px 0 500px;

    &-detail {
      width: $project-left-col-width;
    }
  }

  &-content {
    color: #798086;

    @media (min-width: $desktop-min) {
      margin-left: $project-left-col-width;
      width: $project-right-col-width;
    }
  }

  @media (min-width: $desktop-min) {
    &-subheader-right {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-subheader {
    border-bottom: $project-border;
    margin-bottom: 70px;
    padding-bottom: 66px;

    @media (min-width: $desktop-min) {
      max-width: 611px;

      .flex {
        align-items: flex-end;
        justify-content: space-between;

        > * {
          width: 48.5%;
        }

        h5 {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (min-width: $desktop-min) {
    .see-more {
      align-self: flex-end;
    }
  }

  footer {
    border-top: $project-border;
    font-family: $font-mono;
    font-size: 14px;
    line-height: 14px;
    padding-top: 52px;
    text-transform: uppercase;

    @media (max-width: $mobile-max) {
      p > span {
        display: block;
        margin-bottom: 10px;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;

      @media (min-width: $desktop-min) {
        line-height: 14px;
      }
    }
  }

  .project-row {
    padding-bottom: 88px;
    padding-top: 90px;

    @media (min-width: $desktop-min) {
      display: flex;

      .col {
        width: 50%;
      }
    }

    ul {
      padding-left: 10%;
    }
  }
}
