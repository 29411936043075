@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

/*
@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-LightItalic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
}
*/

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/*
@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-RegularItalic.otf')
    format('opentype');
  font-weight: normal;
  font-style: italic;
}
*/

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/*
@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-MediumItalic.otf')
    format('opentype');
  font-weight: 500;
  font-style: italic;
}
*/

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

/*
@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGrotesk/FoundersGrotesk-BoldItalic.otf')
    format('opentype');
  font-weight: bold;
  font-style: italic;
}
*/

@font-face {
  font-family: 'FoundersGroteskMono';
  src: url('../fonts/FoundersGroteskMono/FoundersGroteskMonoLight.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskMono';
  src: url('../fonts/FoundersGroteskMono/FoundersGroteskMonoRegular.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

/*
@font-face {
  font-family: 'FoundersGroteskMono';
  src: url('../fonts/FoundersGroteskMono/FoundersGroteskMonoMedium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskMono';
  src: url('../fonts/FoundersGroteskMono/FoundersGroteskMonoBold.otf')
    format('opentype');
  font-weight: bold;
  font-style: normal;
}
*/
