section.highlights {
  color: #222B32;
  font-weight: 400;
  overflow: hidden;
  padding-bottom: 58px;
  white-space: nowrap;

  h3 {
    margin-bottom: 136px;

    @media (min-width: $desktop-min) {
      text-align: center;
    }
  }

  .marquee {
    font-size: 90px;
    line-height: 6.5vw;
    margin: 0 0 62px;
    white-space: nowrap;
    width: 100%;

    @media (min-width: $desktop-min) {
      font-size: 120px;
    }

    .alpha, .omega {
      display: inline-block;
    }

    .alpha {
      animation: marqueeAlpha 30s linear infinite;
      animation-delay: -15s;
    }

    .omega {
      animation: marqueeOmega 30s linear infinite;
      animation-delay: 0s;
    }
  }

  /*
  marquee {
    font-size: 90px;
    line-height: 100px;
    margin: 0 0 62px;
    white-space: nowrap;
    width: 100%;

    @media (min-width: $desktop-min) {
      font-size: 120px;
      line-height: 150px;
    }
  }
  */
}

@keyframes marqueeAlpha {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marqueeOmega {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}
