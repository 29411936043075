// Version 1 (JS): see index.js
section.travel-v1 {
  min-height: 100vh;
  padding-top: 17%;

  @media (min-width: $desktop-min) {
    display: flex;
    flex-direction: row;
  }

  h3 {
    margin-bottom: 40px;

    @media (min-width: $desktop-min) {
      margin-bottom: 0;
      width: 40%;
    }

    span {
      display: block;
    }
  }

  .travel-grid {
    position: relative;

    @media (min-width: $desktop-min) {
      width: 60%;
    }

    img {
      margin-bottom: 50px;
      margin-left: 10px;
      margin-right: 10px;
      width: 120px;

      @media (min-width: $desktop-min) {
        margin-left: 50px;
        margin-right: 50px;
        width: 200px;
      }
    }
  }

}

// Version 2 (CSS)
section.travel-v2 {
  min-height: 100vh;
  padding-top: 17%;
  position: relative;

  @media (min-width: $desktop-min) {
    display: flex;
    flex-direction: row;
  }

  .travel-title {
    @media (min-width: $desktop-min) {
      width: 60%;
    }
  }

  h3 {
    margin-bottom: 40px;

    @media (min-width: $desktop-min) {
      margin-bottom: 0;
    }

    span {
      display: block;
    }
  }

  .rellax {
    position: sticky;
    position: -webkit-sticky;
    top: 40%;
  }

  .travel-grid {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (min-width: $desktop-min) {
      flex: 1;
    }

    @media (min-width: 1200px) {
      height: 1760px;
      overflow: hidden;
      position: relative;
      flex: 0 0 918px;
    }

    img {
      @media (max-width: 1199px) {
        height: auto;
        margin-bottom: 32px;
        position: static;
        width: 45%;
      }

      @media (min-width: 1200px) {
        position: absolute;
      }
    }
  }
}
