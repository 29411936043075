* {
  box-sizing: border-box;
}

body {
  background: #12191D;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  padding-top: 100px;

  @media (min-width: $desktop-min) {
    padding-top: 200px;
  }
}

a {
  border-bottom: 1px solid currentColor;
  color: #8D184D;
  font-family: $font-mono;
  font-size: 14px;
  padding-bottom: 8px;
  text-decoration: none;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 60px;
  font-style: normal;
  font-weight: normal;
  line-height: 72px;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 91px;
  font-weight: normal;
  line-height: 109px;
}

h3 {
  color: #FFFFFF;
  font-size: 36px;
  font-weight: normal;
  line-height: 43px;

  @media (min-width: $desktop-min) {
    font-size: 42px;
    line-height: 50px;
  }
}

img,
video {
  max-width: 100%;
}

blockquote {
  color: white;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  margin: 0;
  padding-left: 60px;
  padding-top: 24px;
  position: relative;

  @media (min-width: $desktop-min) {
    font-size: 48px;
    line-height: 58px;
  }

  cite {
    color: rgba(255, 255, 255, 0.6);
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    margin-top: 38px;
  }

  &::before {
    content: '“';
    font-size: 140px;
    font-style: normal;
    font-weight: normal;
    left: 0;
    line-height: 168px;
    position: absolute;
    top: 0;
  }
}

.container {
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;

  @media (min-width: $desktop-min) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.flex {
  display: flex;
  flex-direction: column;

  @media (min-width: $desktop-min) {
    flex-direction: row;
  }
}

.see-more {
  color: #FFFFFF;
  font-family: $font-mono;
  font-size: 14px;
  margin: 58px 0;

  @media (min-width: $desktop-min) {
    flex: 0 0 152px;
    margin: 0;
  }

  img {
    display: block;
    height: 15px;
    margin-bottom: 10px;
    width: 19px;
  }

  span {
    display: block;
  }
}

.title-animated {
  animation: backgroundGradient 8s linear infinite alternate;
  background-image: url('../img/intro-background.png');
  //background-image: url('https://siberia.io/static/media/intro-background.6024dc8c.png');
  background-repeat: no-repeat;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@keyframes backgroundGradient {
  0%   {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.underline {
  border-bottom: none;
  display: inline;
  overflow: hidden;
  position: relative;

  &::after {
    animation: .25s addUnderline;
    background: currentColor;
    bottom: -1px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &:hover::after {
    animation: .5s removeUnderline reverse;
  }
}

@keyframes addUnderline {
  0%   { width: 0;    }
  100% { width: 100%; }
}

@keyframes removeUnderline {
  0%   { width: 100%; }
  100% { width: 0;    }
}

.video-iphone {
  margin-bottom: 15px;
  max-width: 350px;
  overflow: hidden;
  position: relative;

  @media (max-width: $mobile-max) {
    margin-left: auto;
    margin-right: auto;
  }

  video {
    bottom: 3%;
    height: 94%;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 3%;
    z-index: 1;
    width: 100%;
  }

  img {
    display: block;
    position: relative;
    z-index: 2;
  }
}

.video-apple-tv {
  overflow: hidden;
  position: relative;

  video {
    left: 9%;
    margin: 0;
    position: absolute;
    right: 0;
    top: 8%;
    z-index: 1;
    width: 82%;
  }

  img {
    display: block;
    position: relative;
    z-index: 2;
  }
}

.video-macbook {
  overflow: hidden;
  position: relative;

  video {
    left: 17%;
    margin: 0;
    position: absolute;
    top: 20%;
    z-index: 1;
    width: 65%;
  }

  img {
    display: block;
    position: relative;
    z-index: 2;
  }
}

.loading {
  background: #121A20;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 90;
}

.mobile-hide {
  @media (max-width: $mobile-max) {
    display: none;
  }
}

.desktop-hide {
  @media (min-width: $desktop-min) {
    display: none;
  }
}
