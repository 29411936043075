section.clients {
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top: 149px;

  @media (min-width: $desktop-min) {
    padding-top: 17%;
  }

  p {
    color: #798086;
    font-weight: 300;
    line-height: 22px;
    max-width: 300px;

    @media (min-width: $desktop-min) {
      font-size: 16px;
    }

    &.lead {
      color: #888B8E;
      font-weight: 300;
      height: 24px;
      line-height: 30px;
      margin-bottom: 105px;
      margin-top: 24px;
      opacity: 1;
      padding-left: 32px;
      position: relative;

      @media (min-width: $desktop-min) {
        font-size: 18px;
        margin-bottom: 64px;
      }

      &::before {
        background-image: url('../img/eye-off.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 24px;
      }
    }
  }

  .view-more {
    margin: 52px 0;
    text-align: center;

    @media (min-width: $desktop-min) {
      display: none;
    }

    a {
      color: white;
    }
  }

  .clients-row {
    display: none;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $mobile-max) {
      &.mobile-display {
        display: block;
      }
    }

    @media (min-width: $desktop-min) {
      border-bottom: 1px solid #21262B;
      display: flex;
      flex-direction: row;
      margin-bottom: 70px;
    }

    h5 {
      margin-bottom: 10px;
    }

    .client {
      padding: 10px 10px 47px;
      position: relative;

      @media (min-width: $desktop-min) {
        border-left: 1px solid #21262B;
        padding: 10px 50px 53px;
        width: 25%;
      }

      &.alpha {
        border-color: transparent;
      }

      p {
        padding-left: 36px;

        @media (min-width: $desktop-min) {
          padding-left: 0;
        }
      }
    }

    .spacer {
      background: #121A20;
      bottom: -14px;
      display: none;
      height: 28px;
      position: absolute;
      right: -14px;
      width: 28px;
      z-index: 10;

      @media (min-width: $desktop-min) {
        display: block;
      }

      &::after {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px;
        bottom: 0;
        color: #21262B;
        content: '';
        display: block;
        height: 100%;
        text-align: center;
        width: 100%;
        z-index: 11;
      }
    }

    &.even {
      .client .spacer::after {
        background-image: url('../img/clients-union-diamond.png');
      }
      .client:nth-child(even) .spacer::after {
        background-image: url('../img/clients-union-circle.png');
      }
    }

    &.odd {
      .client .spacer::after {
        background-image: url('../img/clients-union-circle.png');
      }
      .client:nth-child(even) .spacer::after {
        background-image: url('../img/clients-union-diamond.png');
      }
    }
  }
}
