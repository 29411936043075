.footer {
  background: white;
  color: black;
  padding-top: 178px;
  position: relative;
  z-index: 101;

  @media (min-width: $desktop-min) {
    padding-top: 280px;
  }

  a {
    &[href^="mailto:"] {
      color: black;
      line-height: 14px;
      margin-bottom: 20px;
    }

    &.logo {
      border-bottom: none;
    }
  }

  h3 {
    color: black;
    font-size: 92px;
    line-height: 100.8%;
    margin-bottom: 81px;

    @media (max-width: $mobile-max) {
      text-transform: capitalize;
    }

    @media (min-width: $desktop-min) {
      font-size: 120px;
      line-height: 134px;
      margin-left: 235px;
    }

    span {
      display: block;
      -webkit-text-fill-color: #FFFFFF;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #000000;
    }
  }

  .bottom {
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
    font-family: $font-mono;
    font-size: 12px;
    font-weight: normal;
    justify-content: space-between;
    line-height: 12px;
    padding-bottom: 81px;
    padding-top: 59px;
    position: relative;
    text-transform: uppercase;

    @media (min-width: $desktop-min) {
      padding-bottom: 110px;
      padding-top: 120px;
    }

    .bottom-col {
      @media (max-width: $mobile-max) {
        &.first,
        &.last {
          bottom: 85px;
          font-size: 15px;
          line-height: 15px;
          position: absolute;
          width: 50%;
        }

        &.first  {
          left: 0;
          text-align: left;
        }

        &.center {
          margin-bottom: 108px;
          text-align: center;
          width: 100%;
        }

        &.last {
          right: 0;
          text-align: right;
        }
      }
    }
  }

  .wwdis {
    letter-spacing: 0.4em;
    margin-top: 23px;
    text-align: center;

    @media (max-width: $mobile-max) {
      font-size: 13px;
      line-height: 13px;
    }

    @media (min-width: $desktop-min) {
      margin-top: 150px;
    }
  }

  .details {
    padding-bottom: 118px;

    @media (max-width: $mobile-max) {
      padding-left: 70px;
    }

    @media (min-width: $desktop-min) {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 258px;
    }

    .details-left {
      padding-bottom: 100px;

      @media (min-width: $desktop-min) {
        padding-bottom: 0;
        width: 40%;
      }

      p {
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 24px;
        width: 80%;
      }
    }

    .details-right {
      border-top: 1px solid black;
      padding-top: 44px;

      @media (min-width: $desktop-min) {
        display: flex;
        width: 33%;
      }

      .emails-label {
        font-size: 28px;
      }

      .emails {
        margin-top: 20px;

        @media (min-width: $desktop-min) {
          margin-top: 0;
          padding-left: 10%;
        }

        div {
          margin-top: 21px;
        }
      }
    }
  }
}
