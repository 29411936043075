section.new-site {
  padding-bottom: 111px;
  padding-top: 17%;

  @media (min-width: $desktop-min) {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding-bottom: 325px;
  }

  a {
    color: #C93176;
  }

  h3 {
    margin-bottom: 99px;

    @media (min-width: $desktop-min) {
      margin-bottom: 0;
      max-width: 40%;
    }
  }
}
