.header-item {
  mix-blend-mode: difference;
  z-index: 100;

  @media (min-width: $desktop-min) {
    justify-content: space-between;
    left: 60px;
    right: 60px;
  }
}

.header-details {
  display: none;
  flex-direction: row;
  font-family: $font-mono;
  font-size: 12px;
  font-weight: normal;
  justify-content: space-between;
  left: 32px;
  line-height: 12px;
  padding-top: 80px;
  position: fixed;
  right: 32px;
  text-transform: uppercase;
  top: 0;

  @media (min-width: $desktop-min) {
    display: flex;
    left: 4.12%;
    right: 4.12%;
  }
}

.header-logo {
  position: sticky;
  text-align: center;
  top: 80px;
  will-change: auto;

  a {
    border: none;
  }
}
