section.intro {
  padding: 234px 32px 157px;
  position: relative;

  @media (min-width: $desktop-min) {
    padding: 17% 60px 223px;
  }

  h1 {
    font-size: 57px;
    line-height: 68px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @media (min-width: $desktop-min) {
      font-size: 91px;
      line-height: 109px;
      padding-left: 29%;
    }
  }

  .background {
    bottom: 0;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}