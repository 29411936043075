.project.playboy {
  a {
    color: #DF4B4B;
  }

  .project-row.top-border {
    border-top: $project-border;
    margin-top: 82px;
  }

  .tags {
    @media (min-width: $desktop-min) {
      margin-top: 60px;
    }
  }

  .hero {
    @media (max-width: $mobile-max) {
      margin-bottom: 98px;
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $desktop-min) {
      background: url('../img/projects/playboy_hero_background.svg');
      background-position: bottom 20% center;
      background-repeat: no-repeat;
      background-size: contain;

      .project-content {
        width: 80%;
      }
    }
  }

  blockquote {
    border-bottom: $project-border;
    margin-bottom: 80px;
    padding-bottom: 86px;
  }

  .project-grid {
    position: relative;

    @media (min-width: $desktop-min) {
      padding-bottom: 140%;
    }

    @media (min-width: 1200px) {
      padding-bottom: 130%;
    }

    .details {
      @media (min-width: 1200px) {
        max-width: 350px;
      }
    }

    &-footer {
      border-top: $project-border;
      position: relative;

      @media (max-width: $mobile-max) {
        margin-top: 50px;
      }

      @media (min-width: $desktop-min) {
        margin-left: 30%;
        width: 70%;

        .flex {
          align-items: flex-end;
          justify-content: space-between;

          .details {
            flex: 0 0 60%;
          }
        }
      }

      @media (min-width: 1400px) {
        bottom: 100px;
      }

      @media (min-width: 1600px) {
        bottom: 120px;
      }

      @media (min-width: 1800px) {
        bottom: 150px;
      }
    }

    video {
      margin-bottom: 15px;
    }

    .background {
      background: url('../img/projects/playboy_grid_mobile.png');
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 800px;
      margin-top: 30px;

      @media (max-width: $mobile-max) {
        margin-top: 80px;
      }

      @media (min-width: $desktop-min) {
        background: url('../img/projects/playboy_grid_desktop.png');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        left: 0;
        position: absolute;
        width: 50%;
      }
    }

    @media (min-width: $desktop-min) {
      .one {
        position: absolute;
        right: 0;
        width: 40%;
      }
    }

    .two {
      margin-top: 8%;
      padding-top: 8%;

      @media (min-width: $desktop-min) {
        border-top: $project-border;
        bottom: 0;
        margin-top: 0;
        padding-bottom: 78px;
        position: absolute;
        right: 0;
        width: 60%;

        .flex {
          align-items: flex-end;
        }

        .see-more {
          margin-left: 5%;
        }
      }
    }
  }

  .bottom-details {
    @media (max-width: $mobile-max) {
      margin-bottom: 80px;

      ul {
        margin-top: 29px;
      }
    }

    @media (min-width: $desktop-min) {
      display: flex;

      > * {
        width: 50%;
      }
    }
  }

  @media (min-width: $desktop-min) {
    .project-row {
      .col.last {
        padding-left: 10%;
      }
    }
  }
}
